import React from "react";
import { Link } from "react-router-dom";
import{ Row, Col, Table } from "reactstrap";

import MultiGENInsertionImg from 'assets/img/navbar/MultiGEN/Insertion/Series-9000-MP-Multipoint.png';

import '../../MobileSideNavbar.css';
import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Multi({mobileNavbarState}){
  const product_data = [
    {
      title: 'MULTIGEN',
      description: 'EPI’s Multi-Point Meter, Offering Accurate Flow Measurement for your Large Pipe, Duct, and Stack Applications',
      type: 1,
      left_small_title: 'INSERTION',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InsertionIcon,
      image: MultiGENInsertionImg,
      default_image: MultiGENInsertionImg,
      left_image: MultiGENInsertionImg,
      right_image: MultiGENInsertionImg
    }
  ]

  const link_dir_inline = '/products/multigen/insertion/multigen';
  const link_dir_insertion = '/products/multigen/insertion/multigen';

  return(
    <>
      <div id="multi" class="sidenav" style={{'padding-top': '50px'}}>
      <Table className="table-shopping" responsive>
        <tbody>
          { product_data.map((value) => {
            return(
              <tr>
                <td>
                  <div className="contentM text-center" style={{'width': '270px'}}>
                    <img style={{'width': '270px', 'height': 'auto'}} alt="..." src={value.image} ></img>
                    <p className="nav-product-title">{ value.title }</p>
                    <p className="nav-product-description">{ value.description }</p>
                    <Row>
                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_insertion + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Insertion
                      </Col>
                    </Row>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </>
  )
}

export default Multi;
